import styles from './products.module.scss';
import { NavBar } from '../../components/navbar/navbar';
import { NextRecruit } from './NextRecruit/NextRecruit';

export function Products() {
    return (
        <div>
            <NavBar />
            <NextRecruit />
        </div>
    );
}