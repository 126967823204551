import React from "react";
import { Link } from "react-router-dom";
import './navbar.css';
import logo from './../../assets/arrow_logo_v1_128.png';
import nextappdevlogo from './../../assets/NEXTAppDevelopmentLogo.png';

export function NavBar() {
    return (
        <div className="navbar">
            <div className="left">
                <img className="logo" src={logo}></img>
                <img className="next_app_dev_logo" src={nextappdevlogo}></img>
            </div>
            <div className="right">
                <Link className="navbar_button" to="/">Home</Link>
                <Link className="navbar_button" to="/products">Products</Link>
                <Link className="navbar_button" to="/about">About Us</Link>
            </div>
        </div>
    )
}