import React from 'react';
import { NavBar } from '../../components/navbar/navbar';
import styles from './about.module.scss';

export function About () {
    return (
        <div>
            <NavBar/>
            <div className={styles.layout}>
                <h1 className={styles.text}>About Us</h1>
                <div className={styles.text}> 
                    We are a small team of developers dedicated to building functional software to make your life easier.
                </div>
                <div className={styles.text}>
                    Contact us at support@nextappdevelopment.com
                </div>
            </div>
        </div>
    );
};