import React, { useState } from 'react';
import styles from './NextRecruit.module.scss';
import nextRecruitLogo from './../../../assets/NEXTRecruit_logo.png';
import image1 from './../../../assets/next-recruit-blog/image1.png';
import image2 from './../../../assets/next-recruit-blog/image2.png';
import image3 from './../../../assets/next-recruit-blog/image3.png';
import image4 from './../../../assets/next-recruit-blog/image4.png';
import image5 from './../../../assets/next-recruit-blog/image5.png';
import image6 from './../../../assets/next-recruit-blog/image6.png';
import { InstallButton } from './InstallButton/InstallButton.tsx';

export function NextRecruit() {
    const chromeWebsiteLink="https://chromewebstore.google.com/detail/next-recruit/nfeambcgnidfipbkbiceclegdedccgeo"
    const [isOpen, setIsOpen] = useState(false);

    const openPopup = () => {
        setIsOpen(true);
    };

    const closePopup = () => {
        setIsOpen(false);
    };
    return (
        <div>
            {isOpen && (
                <div className={styles.overlay} onClick={closePopup}>
                    <button onClick={closePopup} className="close-button">X</button>
                    {/* Youtube videos are 16:9 hence 1200px:675px retains that ratio */}
                    <iframe
                        width="1200px"
                        height="675px"
                        src="https://www.youtube.com/embed/fWNebZhOPLQ?si=Ny4OQpMR_goOHHbF" // Replace this with your video URL
                        title="Video"
                        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        referrerpolicy="strict-origin-when-cross-origin" 
                        allowfullscreen='true'
                    ></iframe>
                </div>
            )}
            <InstallButton link={chromeWebsiteLink}>Install</InstallButton>
            <div className={styles.layout}>
                <img className={styles.nextRecruitLogologo} src={nextRecruitLogo} alt="NEXTRecruit Logo" />
                <div className={styles.body}>
                    <div className={styles.paragraph}>
                        Save time recruiting on LinkedIn with ChatGPT. 
                        Now you can harness the power of AI, built right into your browser.
                        Install now for a free trial!
                    </div>
                    <button className={styles.demoButton} onClick={openPopup}>
                        Watch Demo
                    </button>
                    <img src={image1} alt="Example Image" className={styles.img}/>

                    <div className={styles.paragraph}>
                        Build a custom prompt to fit your personal style. Let ChatGPT do the rest.
                    </div>
                    <div className={styles.sidebyside}>
                        <img src={image2} alt="Example Image" className={styles.img_small}/>
                        <div style={{width:'10px'}}></div>
                        <img src={image3} alt="Example Image" className={styles.img_small}/>
                    </div>

                    <div className={styles.paragraph}>
                        The app will read the user's LinkedIn details to tailor the message to this individual's unique work experience and background.
                    </div>
                    <div className={styles.sidebyside}>
                        <img src={image4} alt="Example Image" className={styles.img_small}/>
                        <div style={{width:'10px'}}></div>
                        <img src={image5} alt="Example Image" className={styles.img_small}/>
                    </div>
                    
                    <div className={styles.paragraph}>
                        Copy paste with one button directly into LinkedIn.
                    </div>
                    <img src={image6} alt="Example Image" className={styles.img}/>
                    <div className={styles.paragraph}>
                        Get your free trial today!
                    </div>
                </div>
            </div>
        </div>
    );
}