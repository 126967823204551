import React from 'react';
import { NavBar } from '../../components/navbar/navbar';
import styles from './home.module.scss';
import { NextRecruit } from './../products/NextRecruit/NextRecruit';

export function Home () {
    return (
        <div>
            <NavBar/>
            <NextRecruit />
        </div>
    );
};