import React from 'react';
import styles from './InstallButton.module.scss';

export function InstallButton(props: { link: string }) {
    const handleClick = () => {
        window.location.href = props.link; // Replace with the desired website URL
    };

    return (
        <button className={styles.styledButton} onClick={handleClick}>
            Install
        </button>
    );
};
